"use client";

import { ProjectType } from "@prisma/client";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { UpsellModal } from "./upsell-modal";
import { CancelStep } from "@/app/(signed-in)/dashboard/my-plan/cancel-modal";

export function AnalyticsWrapper() {
  useEffect(() => {
    const ga4Id = process.env.NEXT_PUBLIC_GA4_ID;
    if (ga4Id) {
      ReactGA.initialize(ga4Id);
    }

    async function setupBrowserAgent() {
      const { BrowserAgent } = await import("@newrelic/browser-agent/loaders/browser-agent");

      new BrowserAgent({
        init: {
          distributed_tracing: { enabled: true },
          privacy: { cookies_enabled: true },
          ajax: { deny_list: ["bam.nr-data.net"] },
        }, // NREUM.init
        info: {
          beacon: "bam.nr-data.net",
          errorBeacon: "bam.nr-data.net",
          licenseKey: process.env.NEXT_PUBLIC_NEWRELIC_LICENSE_KEY,
          applicationID: process.env.NEXT_PUBLIC_NEWRELIC_APP_ID,
          sa: 1,
        }, // NREUM.info
        loader_config: {
          accountID: process.env.NEXT_PUBLIC_NEWRELIC_ACCOUNT_ID,
          trustKey: process.env.NEXT_PUBLIC_NEWRELIC_ACCOUNT_ID,
          agentID: process.env.NEXT_PUBLIC_NEWRELIC_AGENT_ID,
          licenseKey: process.env.NEXT_PUBLIC_NEWRELIC_LICENSE_KEY,
          applicationID: process.env.NEXT_PUBLIC_NEWRELIC_APP_ID,
        }, // NREUM.loader_config
      });
    }
    setupBrowserAgent();
  }, []);

  return (
    <>
      <link rel="preconnect" href="https://www.googletagmanager.com"></link>
    </>
  );
}

type FpsMethod = "ffmpeg" | "calc";

type FreeToolEventPrefix =
  | "freeAiClothesChanger"
  | "freeAiImageGenerator"
  | "freeAiImageUpscaler"
  | "freeAiHeadshotGenerator"
  | "freeAiQrCodeGenerator"
  | "freeAutoSubtitleGenerator"
  | "freeImageBackgroundRemover"
  | "freeLipSync";
type FreeToolEventSuffix =
  | "Render"
  | "Recreate"
  | "Cancel"
  | "Download"
  | "SignUp"
  | "Pricing"
  | "Preset";

type EventName =
  | `upsellModal-${UpsellModal}`
  | "createProjectLink"
  | "fileSizeTooLargeError"
  | "fileInvalidTypeError"
  | "freeToolImageResolutionTooHigh"
  | "videoPlay"
  | `fpsParseFailed-${FpsMethod}`
  | `videoTrimmerStuck`
  | `videoNsfw-${ProjectType}`
  | "videoNsfwModelLoadFailed"
  | `videoRender-${ProjectType}`
  | `dailyReward`
  | `infoDrawerButton`
  // template events
  | `templateModal`
  | `templateFilter`
  | `createWithTemplate`
  | `templateSocialShare`
  | `templateSearch-${"create" | "templates"}`
  // payment events
  | `payment-subscription`
  | `payment-framePack`
  // resubscribe events
  | `planResubscribe-button`
  | `planResubscribe-modal`
  // cancel flow events
  | `planCancel-button`
  | `planCancel-${CancelStep}-step`
  | `planCancel-offer-step-claim`
  | `planCancel-offer-step-reject`
  | `planCancel-reason-checkbox`
  | `planCancel-reason-chat`
  // reset billing events
  | `planBillingReset-button`
  | `planBillingReset-modal`
  | `audioClone`
  // general free tools events
  | `freeToolsFileSizeTooLarge`
  | `freeToolsDailyLimitReachedModal`
  // face swap free usage events
  | `freeFaceSwapPhotoTab`
  | `freeFaceSwapPhotoRender`
  | `freeFaceSwapPhotoCancel`
  | `freeFaceSwapPhotoDownload`
  | `freeFaceSwapPhotoRecreate`
  | `freeFaceSwapVideoTab`
  | `freeFaceSwapVideoRender`
  | `freeFaceSwapVideoCancel`
  | `freeFaceSwapVideoDownload`
  | `freeFaceSwapVideoRecreate`
  | `freeFaceSwapVideoSignup`
  | `freeFaceSwapFileSizeTooLarge`
  | `freeFaceSwapBenefits`
  | `freeFaceSwapImagePreset`
  | `${FreeToolEventPrefix}${FreeToolEventSuffix}`

  // coupon events
  | `couponBanner`
  // create page upsell events
  | `creatorUpgradeUpsellModal`
  // face swap events
  | "selectVideoAdvancedSettings"
  | "selectVideoChangeFile"
  // deprecated events
  | `exploreVideoSocialShare`;

type Action = "view" | "click" | "dismiss" | "upload";

interface EventInputs {
  /**
   * The name of the event to track.
   * Full event name will be in the format of `eventName-action`
   */
  eventName: EventName;

  /**
   * The action taken by the user
   */
  action: Action;

  /**
   * The category of the event. Defaults to the eventName
   * This is a secondary param to help group events
   */
  category?: string;
}

export function trackEvent({ eventName, action, category }: EventInputs) {
  ReactGA.event({ category: category || eventName, action: `${eventName}-${action}` });
}

export function useTrackMount(data: Omit<EventInputs, "action">) {
  useEffect(() => {
    trackEvent({ ...data, action: "view" });
  }, []);
}
